export const menuFunctionality = () => {
    const menuItemHasChildrenArray = document.querySelectorAll('.site-header__menu > .menu-item.menu-item-has-children');
    const siteHeader = document.querySelector('#site-header');
    const mainSubmenu = document.querySelectorAll('.js-sub-menu-wrap');
    const mainSubmenuBlock = document.querySelector('.js-main-submenu-block');
    function isHoverAvailable() {
        return window.matchMedia('(hover: hover)').matches;
    }
    function toggleSubMenuClass(operationType, menuItemHasChildren) {
        menuItemHasChildren && menuItemHasChildren.classList[operationType]('opened');
        siteHeader && siteHeader.classList[operationType]('opened');
        mainSubmenu &&
            mainSubmenu.forEach((subMenu) => {
                var _a;
                const parentTitle = (_a = subMenu === null || subMenu === void 0 ? void 0 : subMenu.dataset) === null || _a === void 0 ? void 0 : _a.parent;
                if (menuItemHasChildren.innerText === parentTitle) {
                    subMenu.classList.toggle('active');
                    const subMenuBlock = subMenu.querySelector('.js-main-submenu-menu');
                    const mainSubmenuImage = subMenu.querySelector('.js-main-submenu-image');
                    const activeMenu = document.querySelector('.js-toggle-active-menu');
                    let subMenuBlockHeight = subMenuBlock === null || subMenuBlock === void 0 ? void 0 : subMenuBlock.offsetHeight;
                    mainSubmenuImage.style.height = `${subMenuBlockHeight}px`;
                    subMenuBlock.addEventListener('mouseenter', () => {
                        subMenu.classList.add('active');
                        activeMenu.classList.add('opened');
                        mainSubmenuBlock.classList.add('active');
                        subMenuBlockHeight = subMenuBlock === null || subMenuBlock === void 0 ? void 0 : subMenuBlock.offsetHeight;
                        mainSubmenuImage.style.height = `${subMenuBlockHeight}px`;
                        menuItemHasChildren.classList.add('opened');
                    });
                    subMenuBlock.addEventListener('mouseleave', () => {
                        subMenu.classList.remove('active');
                        mainSubmenuBlock.classList.remove('active');
                        activeMenu.classList.remove('opened');
                        menuItemHasChildren.classList.remove('opened');
                    });
                }
            });
    }
    menuItemHasChildrenArray &&
        menuItemHasChildrenArray.forEach((hasChildrenItem) => {
            if (isHoverAvailable()) {
                hasChildrenItem.addEventListener('mouseenter', () => toggleSubMenuClass('add', hasChildrenItem));
                hasChildrenItem.addEventListener('mouseleave', () => toggleSubMenuClass('remove', hasChildrenItem));
            }
        });
};
export const activateMobileMenu = () => {
    const addActiveItems = document.querySelectorAll('.js-toggle-active-menu');
    addActiveItems &&
        addActiveItems.forEach((item) => {
            item.classList.toggle('active');
        });
    document.body.classList.toggle('popup-opened');
};
export const titleMenuAction = () => {
    const titleMenuLink = document.querySelectorAll('.site-header__menu .menu-title');
    titleMenuLink &&
        titleMenuLink.forEach((link) => {
            link.addEventListener('click', (event) => {
                event.stopPropagation();
                link && link.classList.toggle('active');
            });
        });
};
