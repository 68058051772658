import '../sass/frontend.scss';
import { Fancybox } from '@fancyapps/ui';
import { activateMobileMenu, menuFunctionality, titleMenuAction, } from './components/menu-functionality';
import Popup from './parts/popup-window';
import Sliders from './components/swiper-init';
import displayFileNameForInput from './components/display-file-name-for-input';
function ready() {
    jQuery('.employee-reviews__slide-text').mCustomScrollbar();
    const popupInstance = new Popup();
    popupInstance.init();
    Fancybox.bind('[data-fancybox="awards"]', {
        hideScrollbar: false,
    });
    Fancybox.bind('[data-fancybox="certificate"]', {
        hideScrollbar: false,
    });
    const siteHeader = document.querySelector('#site-header');
    const form = document.querySelectorAll('form');
    Sliders.InitSwiperIndustry();
    Sliders.initSwipers();
    Sliders.InitSwiperCertificates();
    Sliders.employeeReviewsSlider();
    ScrollReveal().reveal('.right-animation', {
        delay: 375,
        duration: 1500,
        distance: '150%',
        origin: 'right',
        once: true,
    });
    ScrollReveal().reveal('.left-animation', {
        delay: 375,
        duration: 1500,
        distance: '150%',
        origin: 'left',
        once: true,
    });
    menuFunctionality();
    titleMenuAction();
    displayFileNameForInput();
    document.body.addEventListener('click', (e) => {
        var _a;
        const TARGET = e.target;
        const ROLE = (_a = TARGET.dataset) === null || _a === void 0 ? void 0 : _a.role;
        switch (ROLE) {
            case 'burger':
                activateMobileMenu();
                break;
            default:
                break;
        }
    });
    window.document.addEventListener('wpcf7mailsent', () => {
        setTimeout(() => {
            var _a;
            window.location.href = (_a = var_from_php.thanks) !== null && _a !== void 0 ? _a : var_from_php.site_url;
        }, 2000);
    });
    window.document.addEventListener('scroll', () => {
        const operationType = window.scrollY > 100 ? 'add' : 'remove';
        siteHeader && siteHeader.classList[operationType]('scrolled');
        document.body.classList[operationType]('padding-top');
    });
    {
        const operationType = window.scrollY > 100 ? 'add' : 'remove';
        siteHeader && siteHeader.classList[operationType]('scrolled');
        document.body.classList[operationType]('padding-top');
    }
}
window.document.addEventListener('DOMContentLoaded', ready);
