import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
function initSwipers() {
    const swiper = new Swiper('.js-reviews-swiper', {
        modules: [Navigation, Pagination],
        spaceBetween: 0,
        slidesPerView: 1,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
}
function InitSwiperIndustry() {
    const swiper = new Swiper('.js-swiper-industry', {
        modules: [Navigation],
        spaceBetween: 16,
        slidesPerView: 1,
        breakpoints: {
            768: {
                slidesPerView: 2,
            },
            991: {
                slidesPerView: 3,
            },
            1280: {
                slidesPerView: 4,
                spaceBetween: 32,
            },
        },
        navigation: {
            nextEl: '.slider-industries__next',
            prevEl: '.slider-industries__prev',
        },
    });
}
function InitSwiperCertificates() {
    const swiper = new Swiper('.swiper-certificates', {
        modules: [Navigation],
        spaceBetween: 16,
        slidesPerView: 1,
        breakpoints: {
            768: {
                slidesPerView: 2,
            },
            991: {
                slidesPerView: 3,
            },
            1280: {
                slidesPerView: 4,
                spaceBetween: 42,
            },
        },
        navigation: {
            nextEl: '.slider-our-certificate__next',
            prevEl: '.slider-our-certificate__prev',
        },
    });
}
function employeeReviewsSlider() {
    const prevBtn = window.innerWidth > 1470 ? '.swiper-button-prev' : '.swiper-button-prev-mob';
    const nextBtn = window.innerWidth > 1470 ? '.swiper-button-next' : '.swiper-button-next-mob';
    const swiper = new Swiper('.js-employee-reviews-slider', {
        modules: [Navigation],
        spaceBetween: 16,
        slidesPerView: 1,
        breakpoints: {
            768: {
                slidesPerView: 2,
            },
            1470: {
                slidesPerView: 3,
                spaceBetween: 32,
            },
        },
        navigation: {
            nextEl: nextBtn,
            prevEl: prevBtn,
        },
    });
}
const Sliders = { initSwipers, InitSwiperIndustry, employeeReviewsSlider, InitSwiperCertificates };
export default Sliders;
