function displayFileNameForInput() {
    const fileInputs = document.querySelectorAll('.js-file-input');
    if (fileInputs) {
        [...fileInputs].forEach((item) => {
            item.addEventListener('change', (event) => {
                const targetEl = event.target;
                const selectedFile = targetEl.files[0];
                const parentInputWraper = targetEl.closest('.form-box__input-wraper');
                if (!parentInputWraper)
                    return;
                const fileNameDisplay = parentInputWraper.querySelector('.js-file-label');
                if (selectedFile) {
                    fileNameDisplay.textContent = selectedFile.name;
                }
            });
        });
    }
}
export default displayFileNameForInput;
